<template>
  <div class="index">
    <div class="main_layout">
      <a-alert
        message="定期更改密码确保账户安全。"
        class="ant-alert-banner"
        type="success"
        show-icon
      />
      <a-form-model
        :model="formData"
        :rules="rules"
        ref="passForm"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        @submit="handleSubmit"
      >
        <a-form-model-item label="新密码" ref="password" prop="password">
          <a-input-password v-model="formData.password" />
        </a-form-model-item>
        <a-form-model-item label="重复密码" ref="repeat" prop="repeat">
          <a-input-password v-model="formData.repeat" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 12, offset: 8 }">
          <a-button type="primary" html-type="submit">
            修改密码
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import API from "@/request/index";
import { message } from "ant-design-vue";
export default {
  name: "index",
  components: {},
  data() {
    return {
      formData: {
        password: "",
        repeat: ""
      },
      rules: {
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          },
          {
            min: 6,
            message: "最少6位字符",
            trigger: "change"
          }
        ],
        repeat: [
          {
            required: true,
            message: "请重复密码",
            trigger: "blur"
          },
          {
            min: 6,
            message: "最少6位字符",
            trigger: "change"
          },
          {
            validator: this.validatorChange,
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.passForm.validate(async valid => {
        if (valid) {
          let userId = localStorage.getItem("userId");
          let res = await API.changePass({
            password: this.formData.password,
            userId: userId
          });
          if (res.data.code === "0000") {
            message.success("密码修改成功，请牢记");
          }
          this.formData = {
            passWord: "",
            userId: ""
          };
        } else {
          return false;
        }
      });
    },
    validatorChange(rule, value, callback) {
      if (this.formData.password !== value) {
        callback("两次密码输入不一致,请重新输入");
      }
      callback();
    }
  }
};
</script>

<style lang="less" scoped>
.pageHead {
  background: #ffffff;
}
.addBtn {
  margin-bottom: 16px;
}
.ant-alert-banner {
  margin-bottom: 34px;
  border: 0;
  border-radius: 0;
}
</style>
